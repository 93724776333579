<template>
  <div>
    <v-navigation-drawer
        class="non-printable"
        app
        :width="navWidth"
        right
        v-model="setting_dialog"
        temporary
        @input="onNavInput"
    >
      <v-btn
          @click="closeSettings"
          depressed
      >Приховати налаштування
      </v-btn>

      <v-row>
        <v-col cols="12">
          <v-card tile elevation="0">
            <v-card-text class="px-3">
              <v-tabs fixed-tabs color="grey darken-2">
                <v-tab>Основні</v-tab>
                <v-tab>Групування</v-tab>
                <v-tab-item class="mt-3 ml-2">
                  <v-subheader class="pl-1">Відбір</v-subheader>
                  <v-row>
                    <v-col cols="12" v-if="without_flat">
                      <AddressElementSelect v-model="filters.city"
                                            label="Населений пункт" filled
                                            select_type="city"
                      />
                    </v-col>
                    <v-col cols="12" v-if="without_flat">
                      <AddressElementSelect :parent_id="filters.city" v-model="filters.streets"
                                            label="Вулиця(-ки)" multiple filled
                                            select_type="street" use_parent_id
                                            :disabled="!filters.city"
                      />
                    </v-col>
                    <v-col cols="12" v-if="without_flat">
                      <AddressElementSelect :parent_id="filters.streets" v-model="filters.buildings"
                                            label="Будинок(-ки)" multiple filled
                                            select_type="building" use_parent_id
                                            :disabled="!filters.streets.length"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="mt-2">
                    <v-col cols="12" md="12">
                      <span style="font-size: 1rem; font-weight: 500">Сума боргу </span>
                      <v-radio-group
                          v-model="filters.balance_filter"
                          row
                          hide-details
                          class="pt-1 mt-0"
                      >
                        <v-radio color="grey" label="на початок" value="start_balance"></v-radio>
                        <v-radio color="grey" label="на почато мінус оплата" value="start_balance_minus_pay"></v-radio>
                        <v-radio color="grey" label="на кінець" value="end_balance"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="12">
                      <v-switch v-model="filters.balance_filter_use"
                                class="mt-0" hide-details
                                color="secondary"
                                :label="filters.balance_filter_use ? 'Відбір по сумі боргу включений' : 'Відбір по сумі боргу відключений'"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-decimal filled :disabled="!filters.balance_filter_use" label="Борг від"
                                    hide-details v-model.number="filters.balance_from"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-decimal filled :disabled="!filters.balance_filter_use" label="Борг до"
                                    hide-details v-model.number="filters.balance_to"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-switch v-model="filters.use_debt_term"
                                class="mt-0" hide-details
                                :label="filters.use_debt_term ? 'Відбір по терміну заборг. включений' : 'Відбір по терміну заборг. відключений'"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-decimal filled :disabled="!filters.use_debt_term" label="Термін боргу від (міс.)"
                                    hide-details v-model.number="filters.debt_term_start"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-decimal filled :disabled="!filters.use_debt_term" label="Термін боргу до (міс.)"
                                    hide-details v-model.number="filters.debt_term_end"></v-text-field>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item class="mt-3 px-0">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field filled hide-details color="grey"
                                    v-model="filters.court_fee" v-decimal
                                    required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                    label="Розмір судового збору"
                                    :class="!filters.court_fee ? 'req-star' : ''"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <date-component label="Оплатити до" v-model="filters.date_pay_to" req
                                      :class="!filters.date_pay_to ? 'req-star' : ''"
                      />
                    </v-col>
                    <v-col cols="12" class="d-flex">
                      <v-textarea filled
                                  label="Назва послуги"
                                  hide-details
                                  rows="1"
                                  auto-grow
                                  color="grey"
                                  v-model="filters.service_name"/>
                    </v-col>
                    <v-col cols="12">
                      <v-card tile flat>
                        <v-btn color="success" small class="mr-2" @click="addElement">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <v-btn color="secondary" small @click="restoreDefault">
                          <v-icon left>mdi-delete</v-icon>
                          Відновити стандартні
                        </v-btn>
                      </v-card>
                      <v-card
                          v-for="(item, idx) in filters.elements"
                          :key="`gr-idx-${idx}`"
                          style="display: flex; align-items: center"
                          class="my-2 mx-1 py-0"
                          elevation="3"
                      >
                        <div style="flex: 0 0 60px">
                          <v-checkbox color="success" class="ml-3" v-model="item.on"/>
                        </div>
                        <div style="flex: 1; font-weight: 400; font-size: .96rem">
                          <v-textarea hide-details v-model="item.text"
                                      rows="2" auto-grow
                                      style="font-size: .9rem !important; line-height: 1.2rem !important;"
                                      color="grey" class="my-2"/>
                        </div>
                        <div style="flex: 0 0 80px">
                          <div style="display: flex; text-align: center">
                            <v-btn icon @click="pushUp(item)">
                              <v-icon>mdi-arrow-up</v-icon>
                            </v-btn>
                            <v-btn icon @click="pushDown(item)">
                              <v-icon>mdi-arrow-down</v-icon>
                            </v-btn>
                          </div>
                          <div style="text-align: center">
                            <v-btn icon @click="removeItem(item)">
                              <v-icon color="error">mdi-delete</v-icon>
                            </v-btn>
                          </div>
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <div class="pages" id="print">
      <div class="page page-a4 page-separator page-for-print" v-for="(page, index) in pages" :key="`page-${index}`"
           style="margin-bottom: 2px;">
        <div class="report-section" style="flex-wrap: wrap; position:relative;">
          <div class="rp-col-12 receipt" style="visibility: hidden; position: absolute; left: 0; top: 0;"
               id="for-height">
            <div class="receipt-content" style="font-size: .96rem; padding-top: 2px; font-family: 'Times New Roman'">
              <div class="receipt-row" style="font-weight: bold; align-items: center">
                <div
                    style="flex: 1; font-size: 18px; text-decoration: underline; line-height: 1.1rem; text-transform: uppercase; text-align: center">
                  П о п е р е д ж е н н я
                </div>
              </div>
              <div class="receipt-row">
                <div style="flex: 1; line-height: 1.1rem; text-align: center; font-weight: bold">
                  Шановний(-вна) мешканець(-нка): Текстовий Вввллааасник Квааартири <br> будинку за адресою:
                  м. Кам’янець-Подільський, вул. Данила Галицького 215 кв. 123
                </div>
              </div>
              <div class="empty-row" style="height: 4px"></div>
              <div class="receipt-row" style="line-height: 1.1rem">
                <div style="text-align: justify">
                  Ваша заборгованість перед {{ print_data.organization_short_name }} {{ filters.service_name }}
                  станом на {{ date_start | formatDate('DD MMMM YYYY') }} року становить
                  <span style="font-weight: bold">21234.66</span> грн.
                </div>
              </div>
              <div class="receipt-row" style="line-height: 1.1rem; font-size: .92rem">
                <div style="font-weight: bold; text-align: justify">
                  У випадку не погашення Вами до {{ filters.date_pay_to | formatDate('DD MMMM YYYY') }} р. в
                  добровільному порядку
                  вищевказаної заборгованості у суд буде направлена позовна заява про примусове
                  стягнення боргу та накладення арешту на помешкання, що призведе до неможливості
                  відчуження помешкання та понесення Вами додаткових витрат, а саме:
                </div>
              </div>
              <div class="empty-row" style="height: 4px"></div>
              <div class="receipt-row" style="display: block; line-height: 1.1rem">
                <div v-for="(element, idx) in get_ready_elements(null, true)" :key="`el-${idx}`"
                     style="padding-left: 36px">
                  {{ element.text }}
                </div>
              </div>
              <div class="empty-row" style="height: 10px"></div>
              <div class="receipt-row" style="flex: 1; font-size: .8rem; font-weight: bold; padding-bottom: 9px">
                <div style="flex: 1 1 50%; padding-left: 36px; align-self: flex-end">
                  {{ print_data.organization_short_name }}
                </div>
                <div style="flex: 1 1 50%">
                  <div>З попередженням</div>
                  <div style="flex: 50%; display: flex">
                    <div>ознайомлений(-на)</div>
                    <div> ________________________________</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="rp-col-12 receipt" v-for="(col, idx) in items.filter(item => item.page === page)"
               :key="`col-idx-${idx}`">
            <div class="receipt-content" style="font-size: .96rem; padding-top: 2px; font-family: 'Times New Roman'">
              <div class="receipt-row" style="font-weight: bold; align-items: center">
                <div
                    style="flex: 1; font-size: 18px; text-decoration: underline; line-height: 1.1rem; text-transform: uppercase; text-align: center">
                  П о п е р е д ж е н н я
                </div>
              </div>
              <div class="receipt-row">
                <div style="flex: 1; line-height: 1.1rem; text-align: center; font-weight: bold">
                  Шановний(-вна) мешканець(-нка): {{ col.owner }} <br> будинку за адресою: {{ col.address }}
                </div>
              </div>
              <div class="empty-row" style="height: 4px"></div>
              <div class="receipt-row" style="line-height: 1.1rem">
                <div style="text-align: justify">
                  Ваша заборгованість перед {{ print_data.organization_short_name }} {{ server_filters.service_name }}
                  станом на {{ col.debt_date | formatDate('DD MMMM YYYY') }} року становить
                  <span style="font-weight: bold">{{ col.debt_sum | formatToFixed }}</span> грн.
                </div>
              </div>
              <div class="receipt-row" style="line-height: 1.1rem; font-size: .92rem">
                <div style="font-weight: bold; text-align: justify">
                  У випадку не погашення Вами до {{ server_filters.date_pay_to | formatDate('DD MMMM YYYY') }} р. в
                  добровільному порядку
                  вищевказаної заборгованості у суд буде направлена позовна заява про примусове
                  стягнення боргу та накладення арешту на помешкання, що призведе до неможливості
                  відчуження помешкання та понесення Вами додаткових витрат, а саме:
                </div>
              </div>
              <div class="empty-row" style="height: 4px"></div>
              <div class="receipt-row" style="display: block; line-height: 1.1rem">
                <div v-for="(element, idx) in col.elements" :key="`el-${idx}`"
                     style="padding-left: 36px">
                  {{ element.text }}
                </div>
              </div>
              <div class="empty-row" style="height: 10px"></div>
              <div class="receipt-row" style="flex: 1; font-size: .8rem; font-weight: bold; padding-bottom: 9px">
                <div style="flex: 1 1 50%; padding-left: 36px; align-self: flex-end">
                  {{ print_data.organization_short_name }}
                </div>
                <div style="flex: 1 1 50%">
                  <div>З попередженням</div>
                  <div style="flex: 50%; display: flex">
                    <div>ознайомлений(-на)</div>
                    <div> ________________________________</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="separator"></div>
      </div>
    </div>
  </div>
</template>

<script>

import {formatDate, formatToFixed} from "@/filters";
import reportAPI from "@/utils/axios/reports";
import {mapActions, mapGetters} from "vuex";
import {SELECT_SERVICES_WITHOUT_ALL_SERVICE} from "@/store/actions/services";
import {ALERT_SHOW} from "@/store/actions/alert";
import {addDaysToStringDate} from "@/utils/icons"

const default_elements = [
  {on: true, text: '• Судовий збір у розмірі {РозмірСудовогоЗбору} грн. за розгляд позовної заяви в суді'},
  {
    on: true,
    text: '• Виконавчий збір в розмірі 10 % від суми заборгованості: (Підстава: ст.28 Закону України "Про виконаче провадження")'
  },
  {
    on: true,
    text: '• Витрати пов’язані з оплатою правової допомоги адвоката або іншого фахівця в галузі права, який брав участь у справі (Підстава: ст.84 Цивільного процесуального кодексу України)'
  },
  {
    on: true,
    text: '• З 1 травня 2019 р. статтею 26 ЗУ "Про житлово-комунальні послуги №2454-VIII від 07.06.2018 року передбачено стягнення пені у розмірі 0.01% суми боргу за кожень день прострочення'
  },
]

export default {
  name: "warning_standart",
  props: ['organization', 'print_data', 'global_settings', 'scale', 'date_start', 'date_end',
    'generate', 'clear', 'generate_xlsx', 'flat', 'generate_email', 'email_address'],
  components: {
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
  },
  computed: {
    ...mapGetters(
        {
          services: 'getServicesSelectWithoutAll'
        }
    ),
    navWidth() {
      if (this.$vuetify.breakpoint.xs) {
        return '80%'
      }

      if (this.$vuetify.breakpoint.sm) {
        return '60%'
      }

      return '35%'
    },
    without_flat() {
      return !this.flat || Object.keys(this.flat || {}).length === 0
    }
  },
  data() {
    return {
      pages: 1,
      per_page: 3,
      items: [],
      setting_dialog: this.global_settings,
      current_date: this.getCurrentDate(),
      settings_watcher: null,
      generate_watcher: null,
      generate_xlsx_watcher: null,
      generate_email_watcher: null,
      clear_watcher: null,
      date_start_watcher: null,
      local_response_people: this.print_data?.response_people ? this.print_data.response_people.slice() : [],
      pay_date: null,
      ready_elements: [],
      server_filters: [],
      filters: {
        service_name: 'за утримання будинку та прибудинкової території ',
        court_fee: 2481,
        city: null,
        streets: [],
        buildings: [],
        balance_filter: 'start_balance',
        balance_filter_use: false,
        balance_from: 0,
        balance_to: 0,
        use_debt_term: false,
        debt_term_start: 0,
        debt_term_end: 0,
        elements: default_elements,
        date_pay_to: null
      },
    }
  },
  methods: {
    ...mapActions(
        {
          fetchServices: SELECT_SERVICES_WITHOUT_ALL_SERVICE
        }
    ),
    get_ready_elements(row, simple = false) {
      const replace_items = [
        {search: '{РозмірСудовогоЗбору}', value: formatToFixed(this.filters.court_fee || 0, 2)},
        {search: '{Адреса}', value: simple ? '' : row.address},
        {search: '{Борг}', value: simple ? '' : formatToFixed(row.debt_sum)},
        {search: '{ТермінЗаборгованості}', value: simple ? '' : row.debt_month},
        {search: '{Власник}', value: simple ? '' : row.owner},
      ]
      return this.filters.elements.filter(item => item.on).map(item => {
        let value = item.text
        replace_items.forEach(i => {
          value = value.replace(i.search, i.value)
        })

        return {on: item.on, text: value}
      })
    },
    pushUp(payload) {
      let current_index = this.filters.elements.indexOf(payload)
      // let arr_length = this.grouping.length - 1
      let new_arr = []

      if (current_index === 0) {
        new_arr = [...this.filters.elements.slice(1).concat([payload])]
      }
      //
      // if (current_index === 1) {
      //   new_arr = [...this.grouping.slice(1).concat(this.grouping.slice(0,1))]
      // }

      if (current_index >= 1) {
        new_arr = [
          ...this.filters.elements.slice(0, current_index - 1).concat([payload])
              .concat(this.filters.elements.slice(current_index - 1, current_index))
              .concat(this.filters.elements.slice(current_index + 1))
        ]
      }
      this.filters.elements = [...new_arr]
    },
    pushDown(payload) {
      let current_index = this.filters.elements.indexOf(payload)
      let arr_length = this.filters.elements.length - 1
      let new_arr = []

      if (current_index === arr_length) {
        new_arr = [...[payload].concat(this.filters.elements.slice(0, current_index))]
      } else {
        new_arr = [
          ...this.filters.elements.slice(0, current_index)
              .concat(this.filters.elements.slice(current_index + 1, current_index + 2))
              .concat(this.filters.elements.slice(current_index, current_index + 1))
              .concat(this.filters.elements.slice(current_index + 2))
        ]
      }

      this.filters.elements = [...new_arr]
    },
    addElement() {
      this.filters.elements.push(
          {
            on: true,
            text: '',
          }
      )
    },
    restoreDefault() {
      this.filters.elements = default_elements
    },
    removeItem(payload) {
      this.filters.elements.splice(this.filters.elements.indexOf(payload), 1)
    },
    getBasePath() {
      return window.location.origin
    },
    onNavInput(e) {
      if (!e) {
        this.$emit('closeSettings')
      }
    },
    generate_xlsx_file() {
      if (!this.filters.date_pay_to || !this.filters.service_name || !this.filters.court_fee) {
        this.$store.commit(ALERT_SHOW, {
          text: 'Дата оплатити до, назва послугм та сума судового збору - мають бути заповнені',
          color: 'error'
        })
        return
      }
      if (this.date_start && this.date_end) {
        this.$emit('xlsx_loading_status', true)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          filters: {
            city: this.filters.city,
            streets: this.filters.streets,
            buildings: this.filters.buildings,
            service_name: this.filters.service_name,
            court_fee: this.filters.court_fee,
            balance_filter: this.filters.balance_filter,
            balance_filter_use: this.filters.balance_filter_use,
            balance_from: this.filters.balance_from,
            balance_to: this.filters.balance_to,
            use_debt_term: this.filters.use_debt_term,
            debt_term_start: this.filters.debt_term_start,
            debt_term_end: this.filters.debt_term_end,
            date_pay_to: this.filters.date_pay_to,
            elements: this.filters.elements.filter(i => i.on)
          }
        }
        if (this.flat) {
          payload.filters.flat_id = this.flat.id
        }
        payload.filters.user_url = this.getBasePath()

        reportAPI.warning_default_xlsx(payload)
            .then(response => {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a');
              const file_name = `warning_default_${formatDate(this.date_start, 'MM_YYYY')}_${formatDate(this.date_end, 'MM_YYYY')}.xlsx`
              link.href = url;
              link.setAttribute('download', file_name); //or any other extension
              document.body.appendChild(link);
              link.click();
              this.$emit('xlsx_loading_status', false)
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
              this.$emit('xlsx_loading_status', false)
            })
            .finally(() => {
              this.$emit('generate_xlsx')
            })
      }
    },
    generate_email_send() {
      if (!this.filters.date_pay_to || !this.filters.service_name || !this.filters.court_fee) {
        this.$store.commit(ALERT_SHOW, {
          text: 'Дата оплатити до, назва послугм та сума судового збору - мають бути заповнені',
          color: 'error'
        })
        return
      }
      if (this.date_start && this.date_end) {
        this.$emit('email_loading_status', true)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          filters: {
            city: this.filters.city,
            streets: this.filters.streets,
            buildings: this.filters.buildings,
            service_name: this.filters.service_name,
            court_fee: this.filters.court_fee,
            balance_filter: this.filters.balance_filter,
            balance_filter_use: this.filters.balance_filter_use,
            balance_from: this.filters.balance_from,
            balance_to: this.filters.balance_to,
            use_debt_term: this.filters.use_debt_term,
            debt_term_start: this.filters.debt_term_start,
            debt_term_end: this.filters.debt_term_end,
            date_pay_to: this.filters.date_pay_to,
            elements: this.filters.elements.filter(i => i.on)
          },
          email: this.email_address
        }
        if (this.flat) {
          payload.filters.flat_id = this.flat.id
        }
        payload.filters.user_url = this.getBasePath()
        reportAPI.warning_default_email(payload)
            .then(response => response.data)
            .then(data => {
              this.$emit('email_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {color: data.status === 'OK' ? 'success' : 'error', text: data.text})
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$emit('email_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_email')
            })
      }
    },
    generate_report() {
      if (!this.filters.date_pay_to || !this.filters.service_name || !this.filters.court_fee) {
        this.$store.commit(ALERT_SHOW, {
          text: 'Дата оплатити до, назва послугм та сума судового збору - мають бути заповнені',
          color: 'error'
        })
        return
      }
      if (this.date_start && this.date_end) {
        this.$emit('report_loading_status', true)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          filters: {
            city: this.filters.city,
            streets: this.filters.streets,
            buildings: this.filters.buildings,
            service_name: this.filters.service_name,
            court_fee: this.filters.court_fee,
            balance_filter: this.filters.balance_filter,
            balance_filter_use: this.filters.balance_filter_use,
            balance_from: this.filters.balance_from,
            balance_to: this.filters.balance_to,
            use_debt_term: this.filters.use_debt_term,
            debt_term_start: this.filters.debt_term_start,
            debt_term_end: this.filters.debt_term_end,
            date_pay_to: this.filters.date_pay_to,
            elements: this.filters.elements.filter(i => i.on)
          }
        }
        if (this.flat) {
          payload.filters.flat_id = this.flat.id
        }
        reportAPI.warning_default(payload)
            .then(response => response.data)
            .then(data => {
              this.setSplitter(data)
              this.$store.commit(ALERT_SHOW, {
                text: 'Перевірте суму судового збору. За необхідності вкажіть правильну і переформуйте звіт',
                color: 'secondary'
              })
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.expanded = []
              this.$emit('generate_report', !!this.items)
              this.$emit('report_loading_status', false)
            })
      }
    },
    setSplitter(payload) {
      let pages = 1
      let per_page = 1050
      let counter = 0
      let max_pages = 0

      let by_receipt = (document.getElementById('for-height') || {}).offsetHeight

      let by_page = Math.floor(per_page / by_receipt)

      if (!by_receipt) return

      payload.rows.forEach(item => {
        item['elements'] = this.get_ready_elements(item)
        if (counter >= by_page) {
          pages += 1
          counter = 0
        }
        counter += 1
        item['page'] = pages

        if (item['page'] > max_pages) {
          max_pages = item['page']
        }

      })

      if (payload.length > 0 && pages === 0) {
        pages = 1
      }

      if (max_pages > pages) {
        pages = max_pages
      }

      this.pages = pages
      this.per_page = per_page
      this.items = Object.freeze(payload.rows)
      this.server_filters = Object.freeze(payload.filters)
    },
    formatDate,
    getCurrentDate() {
      const date = new Date()
      const year = date.getUTCFullYear()
      const month = date.getUTCMonth() + 1 < 10 ? '0' + (date.getUTCMonth() + 1) : date.getUTCMonth() + 1
      const day = date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate()
      return `${year}-${month}-${day}`
    },
    closeSettings() {
      this.setting_dialog = false
      this.$emit('closeSettings')
    },
    watch_settings() {
      this.settings_watcher = this.$watch(
          'global_settings',
          {
            handler(payload) {
              this.setting_dialog = payload
            }
          }
      )
    },
    watch_generate() {
      this.generate_watcher = this.$watch(
          'generate',
          {
            handler(payload) {
              if (payload) {
                if (!this.filters.date_pay_to) {
                  this.filters.date_pay_to = addDaysToStringDate(this.date_start, 19)
                }
                this.generate_report()
              }
            }
          }
      )
    },
    watch_generate_xlsx() {
      this.generate_xlsx_watcher = this.$watch(
          'generate_xlsx',
          {
            handler(payload) {
              if (payload) {
                if (!this.filters.date_pay_to) {
                  this.filters.date_pay_to = addDaysToStringDate(this.date_start, 19)
                }
                this.generate_xlsx_file()
              }
            }
          }
      )
    },
    watch_clear() {
      this.clear_watcher = this.$watch(
          'clear',
          {
            handler(payload) {
              if (payload) {
                this.items = []
                this.pages = 0
                this.per_page = 0
                this.$emit('afterClear')
              }
            }
          }
      )
    },
    watch_date_start() {
      this.date_start_watcher = this.$watch(
          'date_start',
          {
            handler(payload) {
              if (payload) {
                this.filters.date_pay_to = addDaysToStringDate(payload, 19)
              }
            }
          }
      )
    },
    getContacts() {
      let contacts = ""
      if (this.print_data || this.print_data.contacts) {
        this.print_data.contacts.forEach(item => {
          contacts += `${item.name} ${item.value}  `
        })
      }
      return contacts
    },
    watch_generate_email() {
      this.generate_email_watcher = this.$watch(
          'generate_email',
          {
            handler(payload) {
              if (payload) {
                this.generate_email_send()
              }
            }
          }
      )
    },
  },
  created() {
    this.watch_settings()
    this.watch_generate()
    this.watch_clear()
    this.fetchServices()
    this.watch_generate_xlsx()
    this.watch_date_start()
    this.watch_generate_email()

    if (this.without_flat) {
      reportAPI.report_settings("warning_default")
          .then(response => response.data)
          .then(data => {
            const server_filters = JSON.parse(data)
            Object.keys(this.filters).forEach(key => {
              if (server_filters[key] !== undefined) {
                if (key !== 'date_pay_to') {
                  this.filters[key] = server_filters[key]
                } else {
                  if (!this.filters.date_pay_to) {
                    this.filters.date_pay_to = addDaysToStringDate(this.date_start, 19)
                  }
                }
              }
            })
            if (server_filters['elements'] === null && this.filters.elements === null) {
              this.filters.elements = default_elements
            }
            if (this.filters.court_fee === null || this.filters.court_fee === 0) {
              this.filters.court_fee = 2481
            }
            if (this.filters.balance_filter === null || this.filters.balance_filter === '') {
              this.filters.balance_filter = 'start_balance'
            }
            if (this.filters.service_name === null || this.filters.service_name === '') {
              this.filters.service_name = 'за утримання будинку та прибудинкової території '
            }

          })
    }
  },
  beforeDestroy() {
    if (this.settings_watcher) {
      this.settings_watcher()
    }
    if (this.generate_watcher) {
      this.generate_watcher()
    }
    if (this.clear_watcher) {
      this.clear_watcher()
    }
    if (this.generate_xlsx_watcher) (
        this.generate_xlsx_watcher()
    )
    if (this.date_start_watcher) {
      this.date_start_watcher()
    }
    if (this.generate_email_watcher) {
      this.generate_email_watcher()
    }
  }
}
</script>

<style scoped lang="scss">
$border-color: #cdcdcd;

@media print {
  .page {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }
  .report-table-group-header {
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid;
  }
  .report-table-group-content {
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid;
  }
  //@page {
  //  size: A4 portrait;
  //}

  .page-separator {
    page-break-after: always;
    page-break-inside: avoid;
    page-break-before: avoid;
  }
  //.separator {
  //  page-break-before: right;
  //  //break-before: page;
  //}
}

.page {
  .receipt {
    &:nth-child(1) {
      border-right: 1px dotted $border-color;
      border-bottom: 1px dotted $border-color;
    }

    &:nth-child(2) {
      border-bottom: 1px dotted $border-color;
    }

    &:nth-child(3) {
      border-right: 1px dotted $border-color;
      border-bottom: 1px dotted $border-color;
    }

    &:nth-child(4) {
      border-bottom: 1px dotted $border-color;
    }

    &:nth-child(5) {
      border-right: 1px dotted $border-color;
    }
  }
}

.page {
  .report-section {
    .receipt {
      border: none !important;

      &:first-child {
        margin-top: 0;
      }

      margin-top: 12px;
    }
  }
}

.page-separator {
  page-break-after: always;
  page-break-inside: avoid;
  page-break-before: avoid;
}

.receipt {
  //height: 95mm;
  border: none !important;

  //&:nth-child(even) {
  //  border-left: 1px dotted grey;
  //  border-bottom: 1px dotted grey;
  //}

  .receipt-row {
    display: flex;
  }
}

.receipt-content {
  overflow: hidden;
}

.report-table {
  width: 100%;
  text-align: center;

  .report-table-row:nth-child(1) {
    & > div {
      background-color: rgb(249 249 249 / 74%) !important;
      border-top: 1px solid $border-color;
      border-right: 1px solid $border-color;
      font-weight: 500;

      &:nth-child(1) {
        border-left: 1px solid $border-color;
      }
    }
  }

  .report-table-row:last-child {
    & > div {
      border-bottom: 1px solid $border-color;

      //&:nth-child(1) {
      //  border-left: 1px solid grey;
      //}
    }
  }

  .report-table-row {
    min-height: 16px;
    line-height: 1.12;
    font-size: 11px;

    & > div {
      border-top: 1px solid $border-color;
      border-right: 1px solid $border-color;

      &:nth-child(1) {
        border-left: 1px solid $border-color;
      }
    }
  }

}

.report-table-col {
  &:nth-child(1) {
    span {
      line-height: 0;
      justify-content: flex-start;
      white-space: nowrap;
      text-align: left;
      width: 168px;
      overflow: hidden;
    }
  }

  span {
    padding: 2px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.report-table-group {
  font-size: 12px;
  width: 100%;

  &:last-child {
    border-bottom: 1px solid $border-color !important;
  }

  .report-table-group-header {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    font-size: 12px;
    border-top: 1px solid $border-color;
    font-weight: 500;
    background-color: #f2f2f2;

    .report-table-col {
      border-right: 1px solid $border-color;

      &:nth-child(1) {
        border-left: 1px solid $border-color;
      }
    }

  }

  .report-table-group-content {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    font-size: 12px;

    .report-table-col {
      border-right: 1px solid $border-color;
      border-top: 1px solid $border-color;

      &:nth-child(1) {
        padding-left: 30px !important;
        padding-right: 5px;
        border-left: 1px solid $border-color;
      }
    }
  }
}

.col-group {
  display: flex;
  flex-direction: column;
  width: 100%;

  .col-group-row {
    display: flex;

    &:nth-child(1) {
      border-bottom: 1px solid $border-color;
      text-align: center;
      justify-content: center;
    }

    .report-table-col {
      border-right: 1px solid $border-color;

      &:last-child {
        border-right: none;
      }
    }
  }
}

</style>